import { useIntersectionObserver } from '@vueuse/core';

// This expects the passed element to be a wrapper that contains a v-scrollable element.
// The scroll-container needs to contain v-scrollable-item elements which contain child elements such as divs which have a data-scroll-index property matching to their 0-based index.
// For some reason the v-scrollable element wasn't accessible by passing a ref directly to it, so we access it through the parent wrapper.
export function useControlledScrollable(wrapperElement: MaybeRef<HTMLElement | null | undefined>) {
    const scrollContainer = computed(() => {
        const element = unref(wrapperElement);
        return element ? element.children[0] : undefined;
    });

    const scrollItems = ref<HTMLElement[] | undefined>();

    const currentIndex = ref(0);

    function scroll(index: number) {
        if (!(scrollItems.value && scrollContainer.value)) {
            return;
        }

        const { length } = scrollItems.value;
        const nextIndex = index === length ? 0 : (index < 0 ? length - 1 : index);

        const scrollWidth = scrollContainer.value.getBoundingClientRect().width;

        scrollContainer.value.scroll({ left: nextIndex * scrollWidth, behavior: 'smooth' });
    }

    onMounted(() => {
        if (!scrollContainer.value) {
            // Shouldn't happen, but prevent crashing
            return;
        }

        scrollItems.value = [...scrollContainer.value.querySelectorAll('.scrollable-item > *')] as HTMLElement[];

        // Track which item is showing (in the viewport, since no root defined). This is needed when the content gets scrolled.
        scrollItems.value.forEach((element) => {
            useIntersectionObserver(
                element,
                ([{ isIntersecting }]) => {
                    if (isIntersecting) {
                        currentIndex.value = Number(element.dataset.scrollIndex);
                    }
                },
                // Use a sensible threshold value - the element is considered to be visible only if a sensible minimum amount of it is shown, otherwise small edges or 1 px showing or even being adjacent to the viewport edge might make the element be considered intersecting.
                { threshold: 0.3 },
            );
        });
    });

    return { currentIndex, scroll };
}
